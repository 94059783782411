import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetUserAbsence } from "apis/response";
import { message, Pagination, PaginationProps, Popconfirm, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
import { inputToPhone } from "utils/PhoneString";
import { emptyString, genderString } from "utils";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import MDButton from "components/MDButton";
import WokerAbsenceDialog from "./components/WokerAbsenceDialog";

function WorkAbsenceList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IGetUserAbsence[]>([]);

  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IGetUserAbsence>();

  const handleOpenState = () => setOpen((prev) => !prev);
  const handleSubmitAbsenceUser = (data: any) => {
    const { userId, ...value } = data;
    Apis.postUserBanById(userId, value)
      .then((resolve) => {
        // 그리고 해당 유저를 data에서 찾아서 status 'ban'으로  업데이트 해줘야함.
        setData((prevData) =>
          prevData.map((user) => (user.userId === userId ? { ...user, status: "ban" } : user))
        );
        message.success("유저가 사용정지 처리되었습니다.");
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.message ?? "유저의 사용정리 처리가 실패하였습니다.");
      })
      .finally(() => {
        handleOpenState();
      });
  };

  const handleUnbanUser = (userId: number | string) => {
    // Apis.postUserAbsenceUnbanById(userId)
    //   .then((resolve) => {
    //     // 해당 유저의 상태를 'active'로 업데이트
    //     setData((prevData) =>
    //       prevData.map((user) => (user.userId === userId ? { ...user, status: "general" } : user))
    //     );
    //     message.success("유저의 사용 정지가 해제되었습니다.");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     message.error(error?.message ?? "유저의 사용정지 해제가 실패하였습니다.");
    //   });
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/member-management/work-absence-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/member-management/work-absence-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };

  useEffect(() => {
    Apis.getUserAbsenceList({ page: currentPage, perPage: 10, search, sort, order })
      .then((resolve) => {
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order]);
  const tableColumn: ColumnsType<IGetUserAbsence> = [
    //이름,주소,생년월일, 성별, 결근횟수,평점,회원상태, 최근10회 결근 횟수, 총 누적 결근횟수
    {
      title: "이름",
      dataIndex: "name",
      ellipsis: true,
      render: (name: string) => emptyString(name),
    },
    {
      title: "핸드폰번호",
      dataIndex: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },

    // {
    //   title: "주소",
    //   dataIndex: "firstAddress", // "secondAddress"를 추가 정보로 표시할 수 있도록 고려해야 할 수 있습니다.
    //   ellipsis: true,
    //   render: (firstAddress: string) => emptyString(firstAddress),
    // },
    // {
    //   title: "생년월일",
    //   dataIndex: "birth",
    //   ellipsis: true,
    //   render: (birth: string) => emptyString(birth),
    // },
    // {
    //   title: "성별",
    //   dataIndex: "gender",
    //   ellipsis: true,
    //   align: "center",
    //   render: (gender: string) => genderString(gender),
    // },
    // {
    //   title: "가입일자",
    //   dataIndex: "createdAt",
    //   ellipsis: true,
    //   render: (createdAt: string) => dayjs(createdAt).format("YYYY-MM-DD"),
    // },
    {
      title: "현장명",
      dataIndex: "title",
      ellipsis: true,
      align: "center",
      render: (title: string) => {
        const match = title.match(/\(([^)]+)\)/); // 괄호 안 텍스트 추출
        return match ? match[1] : title; // 괄호 안 텍스트가 있으면 그걸, 없으면 원래 텍스트
      },
    },
    {
      title: "최근 10회 결근 횟수",
      dataIndex: "recentAbsenceCount",
      ellipsis: true,
      align: "center",
    },
    {
      title: "총 결근 횟수",
      dataIndex: "absenceCount",
      ellipsis: true,
      align: "center",
      render: (absenceCount: number) => absenceCount.toLocaleString(),
    },
    {
      title: "정지 관리",
      dataIndex: "status",
      ellipsis: true,
      align: "center",
      render: (status, record) => {
        //뭐시기 값 있으면 분기해서 풀어주는 기능 있어야함.
        if (status === "ban") {
          return (
            <MDButton size="small" color="dark" disabled>
              정지 유저
            </MDButton>
          );
        } else {
          return (
            <MDButton
              size="small"
              color="warning"
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUser(record);
                handleOpenState();
              }}
            >
              사용 정지
            </MDButton>
          );
        }
      },
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3}>
          <Grid item xs={6}>
            {/* <MDButton variant="outlined" color="dark">
              엑셀 다운
            </MDButton> */}
          </Grid>
          <Grid item xs={6}>
            <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/member-management/member-details/${record.userId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>

      {open ? (
        <WokerAbsenceDialog
          user={selectedUser}
          open={open}
          handleOpenState={handleOpenState}
          handleSubmitAbsenceUser={handleSubmitAbsenceUser}
        />
      ) : null}
    </DashboardLayout>
  );
}
export default WorkAbsenceList;
