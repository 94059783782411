import { Rating, Typography } from "@mui/material";
import { InputNumber, message, Table, TableColumnsType } from "antd";
import MDBox from "components/MDBox";
import STcomp from "components/STcomp";
import MDButton from "components/MDButton";

import Apis from "apis/remotes";
import { INewSettlement, INewSettlementById, SettlementData } from "apis/response";
import { calculateAge } from "utils/utilDate";
import { useState } from "react";
import { tableColumn } from "./data";

// 동적으로 상세 테이블 렌더링
const expandedRowRender = (
  recordRow: SettlementData,
  detailSettlementData: { keyId: string; data: INewSettlementById[] }[],
  setDetailSettlementData: React.Dispatch<
    React.SetStateAction<{ keyId: string; data: INewSettlementById[] }[]>
  >,
  loading: boolean,
  editingKey: number,
  editTarget: INewSettlementById | undefined,
  edit: (record: INewSettlementById) => void,
  save: (key: React.Key) => void,
  cancel: () => void,
  handleQuantityChange: (value: number | undefined, key: React.Key) => void,
  handlePaidAmountChange: (value: number | undefined, key: React.Key) => void,
  handleResetAllData: () => void
) => {
  const isEditing = (record: INewSettlementById) => record.settlementId === editingKey;

  const expandColumns: TableColumnsType<INewSettlementById> = [
    { title: "이름", dataIndex: "name", key: "name" },
    {
      title: "평점",
      dataIndex: "score",
      key: "score",
      render: (score, record) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const newScore = formData.get("score");

            if (newScore === null) return;

            const updateScore = (newScoreValue: number, newUserEvaluationId: number) => {
              setDetailSettlementData((prev) =>
                prev.map((item) =>
                  item.keyId === recordRow.workId.toString()
                    ? {
                        ...item,
                        data: item.data.map((d) =>
                          d.userId === record.userId
                            ? { ...d, score: newScoreValue, userEvaluationId: newUserEvaluationId }
                            : d
                        ),
                      }
                    : item
                )
              );
            };

            if (record.userEvaluationId !== 0) {
              Apis.putMatchEvaluationById(record.userEvaluationId, { score: Number(newScore) })
                .then((resolve) => {
                  message.success("평가 수정 완료");
                  updateScore(Number(newScore), record.userEvaluationId);
                })
                .catch((error) => console.log(error.response?.data?.message ?? ""));
            } else {
              Apis.postMatchEvaluation({
                userApplicantId: record.userApplicantId,
                userId: record.userId,
                score: Number(newScore),
                evaluation: "",
              })
                .then((resolve) => {
                  const newUserEvaluationId = resolve.data;
                  message.success("평가 등록 완료");
                  updateScore(Number(newScore), Number(newUserEvaluationId));
                })
                .catch((error) => console.log(error.response?.data?.message ?? ""));
            }
          }}
        >
          <MDBox display={"flex"} gap={1} alignItems={"center"}>
            <Rating name="score" defaultValue={score} />

            <MDButton size="small" color="info" type="submit">
              {record.userEvaluationId === 0 ? "저장" : "수정"}
            </MDButton>
          </MDBox>
        </form>
      ),
    },
    {
      title: "나이",
      dataIndex: "birth",
      key: "birth",
      align: "center",
      render: (birth: string) => calculateAge(birth),
    },
    { title: "계좌번호", dataIndex: "bankAccount", key: "bankAccount" },
    { title: "은행명", dataIndex: "bankName", key: "bankName", align: "center" },
    { title: "예금주", dataIndex: "name", key: "name", align: "center" },
    {
      title: "공수",
      dataIndex: "manDay",
      key: "manDay",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <InputNumber
            value={record.manDay}
            onChange={(value) => handleQuantityChange(value, record.settlementId)}
          />
        ) : (
          record.manDay
        );
      },
    },
    {
      title: "단가",
      dataIndex: "manDayAmount",
      key: "manDayAmount",
      render: (amount: number) => `${amount?.toLocaleString()}원`,
    },
    {
      title: "입금액",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <InputNumber
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value: string) => value!.replace(/\$\s?|(,*)/g, "")}
            value={record.totalAmount}
            onChange={(value) => handlePaidAmountChange(value, record.settlementId)}
            style={{ minWidth: "100px" }}
          />
        ) : (
          `${record.totalAmount?.toLocaleString()}원`
        );
      },
    },
    {
      title: "입금확인시간",
      dataIndex: "paidAt",
      key: "paidAt",
      render: (paidAt) => (paidAt === "" ? "입금예정" : paidAt),
    },
    {
      title: "작업",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <MDBox display="flex" gap={1}>
            <MDButton size="small" color="info" onClick={() => save(record.settlementId)}>
              저장
            </MDButton>
            <MDButton size="small" color="dark" onClick={cancel}>
              취소
            </MDButton>
          </MDBox>
        ) : (
          <MDBox display={"flex"} gap={2} alignItems={"center"}>
            {/* 
              2025.03.05
              입금이 완료된 경우 수정은 불가능함
              입금 전에 수정은 가능함
              고로 paidAt이 빈 문자열이면 이건 아직 입금이 안돼었다는거니까 수정 가능
              */}
            {record.status !== "입금완료" && (
              <MDButton
                size="small"
                color="success"
                disabled={editingKey !== 0}
                onClick={() => edit(record)}
              >
                수정
              </MDButton>
            )}

            {record.status !== "입금완료" && (
              <MDButton
                size="small"
                color="info"
                disabled={editingKey !== 0 || record.status === "입금완료"}
                onClick={
                  () => {
                    // const updatePaidAt = (newPaidAt: string) => {
                    //   setDetailSettlementData((prev) =>
                    //     prev.map((item) =>
                    //       item.keyId === recordRow.workId.toString()
                    //         ? {
                    //             ...item,
                    //             data: item.data.map((d) =>
                    //               d.userId === record.userId
                    //                 ? { ...d, paidAt: newPaidAt, status: "입금완료" }
                    //                 : d
                    //             ),
                    //           }
                    //         : item
                    //     )
                    //   );
                    // };
                    Apis.putSettlementDepositById(record.settlementId)
                      .then((resolve) => {
                        message.success("입금완료");
                        handleResetAllData();
                        // const newPaidAt = new Date();

                        //2024-06-04 14:47:22 문자열로 바꿔서 전달.
                        // const formattedDate = newPaidAt
                        //   .toISOString()
                        //   .replace("T", " ")
                        //   .substring(0, 19);
                        // updatePaidAt(formattedDate);
                      })
                      .catch((error) => console.log(error));
                  }
                  // handleSettlementDeposit(record.settlementId)
                }
              >
                입금 확인
              </MDButton>
            )}
          </MDBox>
        );
      },
    },
  ];

  const data =
    detailSettlementData.find((item) => item.keyId === recordRow.workId.toString())?.data || [];
  console.log(
    "expandedRowRender 호출됨 - workId:",
    recordRow.workId,
    "data:",
    data,
    "loading:",
    loading
  );

  if (loading) {
    return <Typography>로딩 중...</Typography>;
  }

  return (
    <Table<INewSettlementById>
      columns={expandColumns}
      dataSource={data.length > 0 ? data : []}
      pagination={false}
    />
  );
};

interface SettlementTableProps {
  work: INewSettlement;
  navigate: (path: string) => void;
  handleResetAllData: () => void;
}

const SettlementTable: React.FC<SettlementTableProps> = ({
  work,
  navigate,
  handleResetAllData,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [detailSettlementData, setDetailSettlementData] = useState<
    { keyId: string; data: INewSettlementById[] }[]
  >([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [editingKey, setEditingKey] = useState<number>(0);
  const [editTarget, setEditTarget] = useState<INewSettlementById | undefined>(undefined);

  // 통계 상태 분리 (초기 값은 고정)
  const totalSettlementUserCount = work.settlementData.reduce(
    (sum, work) => sum + work.settlementUserCount,
    0
  );

  // 정산 된 인원
  const settledUserCount = work.settlementData.reduce(
    (sum, work) => sum + work.settledUserCount,
    0
  );
  // 미 정산 인원
  const unsettledUserCount = work.settlementData.reduce(
    (sum, work) => sum + work.unsettledUserCount,
    0
  );

  const totalManDayCount = work.settlementData.reduce((sum, work) => sum + work.manDayCount, 0);

  const totalPayAmount = work.settlementData.reduce((sum, work) => sum + work.totalAmount, 0);

  // 정산된 금액
  const settledTotalAmount = work.settlementData.reduce(
    (sum, work) => sum + work.settledTotalAmount,
    0
  );

  // 미 정산 금액
  const unsettledTotalAmount = work.settlementData.reduce(
    (sum, work) => sum + work.unsettledTotalAmount,
    0
  );

  const averagePayAmount = totalPayAmount / totalManDayCount || 0;

  /** 상세 데이터 가져오기 */
  const fetchDetailSettlement = async (workId: number) => {
    const cachedData = detailSettlementData.find((item) => item.keyId === workId.toString());
    if (cachedData) {
      console.log("캐시에서 데이터 가져옴 - workId:", workId, "data:", cachedData.data);
      return cachedData.data;
    }

    setLoadingDetail(true);
    try {
      const response = await Apis.getSettlementById(workId);
      const newData: INewSettlementById[] = response.data.data;
      console.log("API에서 가져온 데이터:", newData);
      setDetailSettlementData((prev) => [
        ...prev.filter((item) => item.keyId !== workId.toString()),
        { keyId: workId.toString(), data: newData },
      ]);

      setLoadingDetail(false);
      return newData;
    } catch (error) {
      console.error("상세 데이터 불러오기 실패:", error);
      setLoadingDetail(false);
      return [];
    }
  };

  const edit = (record: INewSettlementById) => {
    setEditTarget(record);
    setEditingKey(record.settlementId);
  };

  const cancel = () => {
    if (editTarget) {
      setDetailSettlementData((prev) =>
        prev.map((item) =>
          item.keyId === editTarget.userId.toString()
            ? {
                ...item,
                data: item.data.map((d) =>
                  d.settlementId === editTarget.settlementId ? { ...editTarget } : d
                ),
              }
            : item
        )
      );
    }
    setEditingKey(0);
    setEditTarget(undefined);
  };

  const save = async (key: React.Key) => {
    try {
      const dataToSave = detailSettlementData
        .flatMap((item) => item.data)
        .find((d) => d.settlementId === key);
      if (dataToSave) {
        await Apis.putSettlementById(key, {
          manDay: dataToSave.manDay,
          totalAmount: dataToSave.totalAmount,
        });
        message.success("수정 완료");
        handleResetAllData();

        setEditingKey(0);
        setEditTarget(undefined);
      }
    } catch (error) {
      console.log("저장 실패:", error);
      message.error("수정 실패");
    }
  };

  const handleQuantityChange = (value: number | undefined, key: React.Key) => {
    setDetailSettlementData((prev) =>
      prev.map((item) =>
        item.data.some((d) => d.settlementId === key)
          ? {
              ...item,
              data: item.data.map((d) =>
                d.settlementId === key
                  ? { ...d, manDay: value || 0, totalAmount: (value || 0) * d.manDayAmount }
                  : d
              ),
            }
          : item
      )
    );
  };

  const handlePaidAmountChange = (value: number | undefined, key: React.Key) => {
    setDetailSettlementData((prev) =>
      prev.map((item) =>
        item.data.some((d) => d.settlementId === key)
          ? {
              ...item,
              data: item.data.map((d) =>
                d.settlementId === key
                  ? { ...d, totalAmount: value || 0, manDay: (value || 0) / d.manDayAmount }
                  : d
              ),
            }
          : item
      )
    );
  };

  /** 입금완료 버튼 클릭 */
  const handleSettlementDeposit = (settlementId: string | number) => {
    // const manDayAndTotalPay = data.find((item) => item.settlementId === settlementId);
  };

  return (
    <MDBox py={1}>
      <MDBox p={1}>
        <Typography variant="h5">{work.workDay}</Typography>
      </MDBox>
      <Table
        scroll={{ x: "max-content" }}
        showHeader={false}
        pagination={false}
        rowKey={(record) => record.workId.toString()}
        columns={tableColumn}
        dataSource={work.settlementData}
        expandable={{
          expandedRowRender: (record) =>
            expandedRowRender(
              record,
              detailSettlementData,
              setDetailSettlementData,
              loadingDetail,
              editingKey,
              editTarget,
              edit,
              save,
              cancel,
              handleQuantityChange,
              handlePaidAmountChange,
              handleResetAllData
            ),
          expandedRowKeys,
          onExpand: async (expanded, record) => {
            console.log("onExpand 호출 - expanded:", expanded, "workId:", record.workId);
            if (expanded) {
              const data = await fetchDetailSettlement(record.workId);
              console.log("onExpand - 가져온 데이터:", data);
              if (data && data.length > 0) {
                setExpandedRowKeys([record.workId.toString()]);
                console.log("expandedRowKeys 설정됨:", [record.workId.toString()]);
              }
            } else {
              setExpandedRowKeys([]);
              console.log("expandedRowKeys 초기화됨");
            }
          },
          expandIcon: ({ expanded, onExpand, record }) => (
            <MDButton
              size="small"
              color="info"
              onClick={(e) => {
                e.stopPropagation();
                console.log("expandIcon 클릭 - workId:", record.workId);
                onExpand(record, e as any);
              }}
            >
              {expanded ? "-" : "+"}
            </MDButton>
          ),
        }}
        style={{ cursor: "pointer" }}
        onRow={(record) => ({
          onClick: () => navigate(`/announcement-management/site-details/${record.workId}`),
        })}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <MDBox display={"flex"} flexDirection={"column"} gap={1}>
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"미 정산 인원"}
                    amount={unsettledUserCount}
                  />
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"정산 된 인원"}
                    amount={settledUserCount}
                  />
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"총 정산 인원"}
                    amount={totalSettlementUserCount}
                  />
                </MDBox>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={3}>
                <STcomp
                  color="secondary"
                  variant="contained"
                  title={"공수"}
                  amount={totalManDayCount}
                />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <STcomp
                  color="secondary"
                  variant="contained"
                  title={"평균단가"}
                  amount={`${averagePayAmount.toLocaleString()}원`}
                />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <MDBox display={"flex"} flexDirection={"column"} gap={1}>
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"미 정산 금액"}
                    amount={`${unsettledTotalAmount.toLocaleString()}원`}
                  />
                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"정산 된 금액"}
                    amount={`${settledTotalAmount.toLocaleString()}원`}
                  />

                  <STcomp
                    color="secondary"
                    variant="contained"
                    title={"총 정산 금액"}
                    amount={`${totalPayAmount.toLocaleString()}원`}
                  />
                </MDBox>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </MDBox>
  );
};

export default SettlementTable;
