/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts

import AdminList from "layouts/categories/adminManagement/admins/admin-list";
import AdminDetails from "layouts/categories/adminManagement/admins/admin-details";

import MemberList from "layouts/categories/memberManagement/members/member-list";

// import SiteList from "layouts/SiteList";

// @mui icons
import Icon from "@mui/material/Icon";
import NewAdmin from "layouts/categories/adminManagement/admins/new-admin";
import MyProfile from "layouts/categories/adminManagement/myprofile";
import MemberDetails from "layouts/categories/memberManagement/members/member-details";

// import CouponHistoryList from "layouts/categories/memberManagement/couponHistory/coupon-list";
// import CouponHistoryDetails from "layouts/categories/memberManagement/couponHistory/coupon-details";
// import CouponList from "layouts/categories/memberManagement/couponMangement/coupon-list";
// import CouponDetails from "layouts/categories/memberManagement/couponMangement/coupon-details";
import BannerList from "layouts/categories/memberManagement/banners/banner-list";
import BannerDetails from "layouts/categories/memberManagement/banners/banner-detail";

import NewAnnouncement from "layouts/categories/announcementManagement/announcements/new-announcement";

// import SiteList from "layouts/categories/announcementManagement/sites/site-list";
import NewBanner from "layouts/categories/memberManagement/banners/new-banner";
import SignInBasic from "layouts/authentication/sign-in/basic";
import WithDrawMembership from "layouts/authentication/withdraw-membership/basic";
import SiteDetails from "layouts/categories/announcementManagement/sites/site-details";

import EmploymentList from "layouts/categories/memberManagement/employments/employment-list";
import EmploymentDetails from "layouts/categories/memberManagement/employments/employment-details";
import NewEmployment from "layouts/categories/memberManagement/employments/new-employment";
import UpdateBanner from "layouts/categories/memberManagement/banners/update-banner-detail";
import QNAList from "layouts/categories/csManagement/qnaManagement/qna-list";
import ReviewList from "layouts/categories/csManagement/reviewManagement/review-list";
import CouponList from "layouts/categories/couponManagement/coupons/coupon-list";
import UpdateEmployment from "layouts/categories/memberManagement/employments/update-employment";
import QNADetails from "layouts/categories/csManagement/qnaManagement/qna-details";
import SettlementListVer2 from "layouts/categories/settlementManagement/settlements/settlement-list-ver2";
import SettlementDetailsVer2 from "layouts/categories/settlementManagement/settlements/settlement-details-ver2";
import WorkerList from "layouts/categories/laborManagement/workerManagement/worker-list";
import OnlineWorkerList from "layouts/categories/laborManagement/workerManagement/online-worker-list";
import WorkerDetails from "layouts/categories/laborManagement/workerManagement/worker-details";
import SiteListNew from "layouts/categories/announcementManagement/sites/site-list-new";
import OnBoards from "layouts/dashboards/onboards";
import SiteBookListNew from "layouts/categories/announcementManagement/sites/site-book-list-new";
import NoticeList from "layouts/categories/noticeManagement/notices/notice-list";
import NoticeDetails from "layouts/categories/noticeManagement/notices/notice-details";
import UpdateNotice from "layouts/categories/noticeManagement/notices/update-notice-detail";
import WorkChatList from "layouts/categories/chatManagement/chat/work-chat-list";
import UpdateAnnouncement from "layouts/categories/announcementManagement/announcements/update-announcement";
import BuddyChatList from "layouts/categories/chatManagement/chat/buddy-chat-list";
import ChatDetails from "layouts/categories/chatManagement/chat/chat-details";
import ChatReports from "layouts/categories/chatManagement/report/report-list";
import ChatReport from "layouts/categories/chatManagement/report/report-details";
import CreateNotice from "layouts/categories/noticeManagement/notices/create-notice";
import EventList from "layouts/categories/noticeManagement/events/event-list";
import EventDetails from "layouts/categories/noticeManagement/events/event-details";
import CreateEvent from "layouts/categories/noticeManagement/events/create-event";
import UpdateEvent from "layouts/categories/noticeManagement/events/update-event-detail";
import ChatNoticeList from "layouts/categories/chatManagement/notice/chat-notice-list";
import CreateChatNotice from "layouts/categories/chatManagement/notice/create-chat-notice";
import SpecialOffer from "layouts/categories/announcementManagement/sites/spcial-offer";
import CreateCoupon from "layouts/categories/couponManagement/coupons/create-coupon";
import CouponDetails from "layouts/categories/couponManagement/coupons/coupon-details";
import CouponSettlementList from "layouts/categories/settlementManagement/couponSettlement/coupon-settlement-list";
import Setting from "layouts/categories/settingManagement";
import BugList from "layouts/categories/bugManagement/bug-list";
import NewBug from "layouts/categories/bugManagement/new-bug";
import BugListAlpha from "layouts/categories/bugManagement/bug-list-alpha";
import WorkAbsenceList from "layouts/categories/memberManagement/workAbsence/workAbsence-list";
import WorkApplyTimeList from "layouts/categories/utilityManagement/workApplyTime/list";
import WorkApplyTimeDetails from "layouts/categories/utilityManagement/workApplyTime/details";
import WorkApproveTimeDetails from "layouts/categories/utilityManagement/workApproveTime/details";
import WorkApproveTimeList from "layouts/categories/utilityManagement/workApproveTime/list";
import ThumbNailList from "layouts/categories/utilityManagement/siteThumbNailManagement/thumbnail-list";
import ThumbNailDetails from "layouts/categories/utilityManagement/siteThumbNailManagement/thumbnail-details";
import NewThumbNail from "layouts/categories/utilityManagement/siteThumbNailManagement/thumbnail-new";
// import InsuranceList from "layouts/categories/memberManagement/insurance/insurance-list";
// import InsuranceDetails from "layouts/categories/memberManagement/insurance/insurance-details";
import BlackUserList from "layouts/categories/memberManagement/blackUsers/black-user-list";
import WorkFieldEvaluationList from "layouts/categories/laborManagement/workFieldEvaluationManagement/work-field-evaluation-list";
import WorkFieldEvaluationDetails from "layouts/categories/laborManagement/workFieldEvaluationManagement/work-field-evaluation-details";
import PyeongtaekStatisticsList from "layouts/categories/utilityManagement/pyeongtaekStatisticsManagement/list";
import InsuranceStatisticsList from "layouts/categories/utilityManagement/insuranceStatisticsManagement/list";
// import InsuranceCertificationList from "layouts/categories/utilityManagement/insuranceCertificationManagement/list";
import InsuranceStatisticsDetails from "layouts/categories/utilityManagement/insuranceStatisticsManagement/details";
import TrackingManagementView from "layouts/categories/utilityManagement/trackingManagement";
import NewAnnouncementLoti from "layouts/categories/announcementManagement/announcements/new-announcement-loti";
import UpdateAnnouncementLoti from "layouts/categories/announcementManagement/announcements/update-announcement-loti";
import LotteryThumbNailList from "layouts/categories/utilityManagement/lotteryThumbNailManagement/thumbnail-list";
import LotteryThumbNailDetails from "layouts/categories/utilityManagement/lotteryThumbNailManagement/thumbnail-details";
import LotteryNewThumbNail from "layouts/categories/utilityManagement/lotteryThumbNailManagement/thumbnail-new";
import PartnerList from "layouts/categories/partnerManagement/partners/partner-list";
import PartnerDetails from "layouts/categories/partnerManagement/partners/partner-details";
import TemplateList from "layouts/categories/announcementManagement/templates/template-list";

const routes = [
  // 로그인
  {
    name: "memebership",
    key: "memebership",
    route: "/authentication/withdraw/memebership",
    component: <WithDrawMembership />,
    hidden: true,
  },
  // 로그인
  {
    name: "Basic",
    key: "basic",
    route: "/authentication/sign-in/basic",
    component: <SignInBasic />,
    hidden: true,
  },
  // 대시보드
  {
    type: "collapse",
    name: "대시보드",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <OnBoards />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "관리자 계정관리",
    key: "admin-list",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    route: "/admin-list",
    component: <AdminList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "관리자 계정관리",
    key: "admin-list",
    route: "/admin-list/:id",
    component: <AdminDetails />,
    noCollapse: true,
    hidden: true,
  },
  {
    name: "관리자 계정관리",
    key: "new-admin",
    route: "/new-admin",
    component: <NewAdmin />,
    hidden: true,
  },
  {
    name: "관리자 계정관리",
    key: "my-profile",
    route: "/my-profile",
    component: <MyProfile />,
    hidden: true,
  },
  // 유틸리티
  {
    type: "collapse",
    name: "유틸리티",
    key: "utility-management",
    icon: <Icon fontSize="medium">add_alarm</Icon>,
    collapse: [
      {
        // 일자리 지원
        name: "일자리 지원 시간",
        key: "work-apply-time-list",
        route: "/utility-management/work-apply-time-list",
        component: <WorkApplyTimeList />,
      },
      {
        // 일자리 지원 상세
        name: "일자리 지원 시간 상세",
        key: "work-apply-time-detail",
        route: "/utility-management/work-apply-time-detail/:id",
        component: <WorkApplyTimeDetails />,
        hidden: true,
      },
      {
        // 일자리 승인
        name: "일자리 승인 시간",
        key: "work-approve-time-list",
        route: "/utility-management/work-approve-time-list",
        component: <WorkApproveTimeList />,
      },
      {
        // 일자리 승인 상세
        name: "일자리 승인 시간 상세",
        key: "work-approve-time-detail",
        route: "/utility-management/work-approve-time-detail/:id",
        component: <WorkApproveTimeDetails />,
        hidden: true,
      },

      {
        // 공고 썸네일 관리 리스트
        name: "공고 썸네일 관리",
        key: "work-thumbnail-list",
        route: "/utility-management/work-thumbnail-list",
        component: <ThumbNailList />,
      },
      {
        // 공고 썸네일 관리 상세
        name: "공고 썸네일 상세",
        key: "work-thumbnail-detail",
        route: "/utility-management/work-thumbnail-detail/:id",
        component: <ThumbNailDetails />,
        hidden: true,
      },
      {
        // 공고 썸네일 생성
        name: "공고 썸네일 생성",
        key: "new-work-thumbnail",
        route: "/utility-management/new-work-thumbnail",
        component: <NewThumbNail />,
        hidden: true,
      },
      {
        // 로터리 공고 썸네일 관리 리스트
        name: "로터리 공고 썸네일 관리",
        key: "lottery-work-thumbnail-list",
        route: "/utility-management/lottery-work-thumbnail-list",
        component: <LotteryThumbNailList />,
      },
      {
        // 로터리 공고 썸네일 관리 상세
        name: "로터리 공고 썸네일 상세",
        key: "lottery-work-thumbnail-detail",
        route: "/utility-management/lottery-work-thumbnail-detail/:id",
        component: <LotteryThumbNailDetails />,
        hidden: true,
      },
      {
        // 로터리 공고 썸네일 생성
        name: "로터리 공고 썸네일 생성",
        key: "lottery-new-work-thumbnail",
        route: "/utility-management/lottery-new-work-thumbnail",
        component: <LotteryNewThumbNail />,
        hidden: true,
      },

      // {
      //   // 고용보험 인증회원
      //   name: "고용보험 인증회원",
      //   key: "insurance-certification-list",
      //   route: "/utility-management/insurance-certification-list",
      //   component: <InsuranceCertificationList />,
      // },

      {
        // 고용보험 인증회원 통계
        name: "고용보험 인증회원 통계",
        key: "insurance-statistics-list",
        route: "/utility-management/insurance-statistics-list",
        component: <InsuranceStatisticsList />,
      },
      {
        // 고용보험 인증회원 통계 상세
        name: "고용보험 인증회원 통계 상세",
        key: "insurance-statistics-detail",
        route: "/utility-management/insurance-statistics-detail/:id",
        component: <InsuranceStatisticsDetails />,
        hidden: true,
      },
      {
        // 평택 신규 회원 통계
        name: "평택 신규회원 통계",
        key: "pyeongtaek-statistics-list",
        route: "/utility-management/pyeongtaek-statistics-list",
        component: <PyeongtaekStatisticsList />,
      },

      {
        // 일자리 지원 상세
        name: "트래킹 관리",
        key: "tracking-view",
        route: "/utility-management/tracking-view",
        component: <TrackingManagementView />,
        // hidden: true,
      },
    ],
  },
  // 회원관리
  {
    type: "collapse",
    name: "회원관리",
    key: "member-management",
    icon: <Icon fontSize="medium">group</Icon>,
    collapse: [
      {
        // 회원관리
        name: "회원정보",
        key: "member-list",
        route: "/member-management/member-list",
        component: <MemberList />,
      },
      {
        // 회원관리 상세조회

        name: "회원정보 상세조회",
        key: "member-details",
        route: "/member-management/member-details/:id",
        component: <MemberDetails />,
        hidden: true,
      },

      // {
      //   // 고용보험
      //   name: "고용보험",
      //   key: "insurance-list",
      //   route: "/member-management/insurance-list",
      //   component: <InsuranceList />,
      // },
      // {
      //   // 고용보험 상세조회
      //   name: "고용보험 상세조회",
      //   key: "insurance-details",
      //   route: "/member-management/insurance-details/:id",
      //   component: <InsuranceDetails />,
      //   hidden: true,
      // },
      {
        // 배너관리
        name: "배너관리",
        key: "banner-list",
        route: "/member-management/banner-list",
        component: <BannerList />,
      },
      {
        // 배너관리 상세조회
        name: "배너관리 상세조회",
        key: "banner-details",
        route: "/member-management/banner-details/:id",
        component: <BannerDetails />,
        hidden: true,
      },
      {
        // 배너관리 수정 상세조회
        name: "배너관리 수정 상세조회",
        key: "update-banner-details",
        route: "/member-management/update-banner-details/:id",
        component: <UpdateBanner />,
        hidden: true,
      },
      {
        // 배너관리 등록
        name: "배너관리 등록",
        key: "new-banner",
        route: "/member-management/new-banner",
        component: <NewBanner />,
        hidden: true,
      },
      {
        // 인력사무소관리
        name: "인력사무소관리",
        key: "employment-list",
        route: "/member-management/employment-list",
        component: <EmploymentList />,
      },
      {
        // 인력사무소관리 상세조회
        name: "인력사무소관리 상세조회",
        key: "employment-details",
        route: "/member-management/employment-details/:id",
        component: <EmploymentDetails />,
        hidden: true,
      },
      {
        // 인력사무소관리 수정
        name: "인력사무소관리 수정",
        key: "update-employment",
        route: "/member-management/update-employment/:id",
        component: <UpdateEmployment />,
        hidden: true,
      },
      {
        // 인력사무소관리 추가
        name: "인력사무소관리 추가",
        key: "new-employment",
        route: "/member-management/new-employment",
        component: <NewEmployment />,
        hidden: true,
      },
      {
        // 결근자관리
        name: "결근자관리",
        key: "work-absence-list",
        route: "/member-management/work-absence-list",
        component: <WorkAbsenceList />,
      },
      {
        // 블랙리스트관리
        name: "정지유저관리",
        key: "black-user-list",
        route: "/member-management/black-user-list",
        component: <BlackUserList />,
      },
    ],
  },
  // 공고관리
  {
    type: "collapse",
    name: "공고관리",
    key: "announcement-management",
    icon: <Icon fontSize="medium">announcement</Icon>,
    collapse: [
      {
        // 현장관리
        name: "공고관리",
        key: "site-list-new",
        route: "/announcement-management/site-list-new",
        component: <SiteListNew />,
      },
      {
        // 예약 공고 관리
        name: "예약 공고 관리",
        key: "site-book-list-new",
        route: "/announcement-management/site-book-list-new",
        component: <SiteBookListNew />,
      },
      {
        // 특별 제안
        name: "특별 제안",
        key: "special-offer",
        route: "/announcement-management/special-offer/:id",
        component: <SpecialOffer />,
        hidden: true,
      },
      {
        // 현장관리 상세조회
        name: "현장관리 상세조회",
        key: "site-details",
        route: "/announcement-management/site-details/:id",
        component: <SiteDetails />,
        hidden: true,
      },
      {
        // 공고추가
        name: "공고추가",
        key: "new-announcement",
        route: "/announcement-management/new-announcement",
        component: <NewAnnouncement />,
        hidden: true,
      },
      {
        // 로터리 공고추가
        name: "로터리 공고추가",
        key: "lottery-new-announcement",
        route: "/announcement-management/lottery-new-announcement",
        component: <NewAnnouncementLoti />,
        hidden: true,
      },
      {
        // 공고수정
        name: "공고수정",
        key: "update-announcement",
        route: "/announcement-management/update-announcement/:id",
        component: <UpdateAnnouncement />,
        hidden: true,
      },
      {
        // 로터리 공고수정
        name: "로터리 공고수정",
        key: "lottery-update-announcement",
        route: "/announcement-management/lottery-update-announcement/:id",
        component: <UpdateAnnouncementLoti />,
        hidden: true,
      },
      {
        // 템플릿 관리
        name: "템플릿 관리",
        key: "template-list",
        route: "/announcement-management/template-list",
        component: <TemplateList />,
      },
    ],
  },
  // 근로관리
  {
    type: "collapse",
    name: "근로관리",
    key: "labor-management",
    icon: <Icon fontSize="medium">work</Icon>,
    collapse: [
      {
        // 근로자 관리
        name: "근무중 관리",
        key: "online-worker-list",
        route: "/labor-management/online-worker-list",
        component: <OnlineWorkerList />,
      },
      {
        // 근로자 관리
        name: "근로자 관리",
        key: "worker-list",
        route: "/labor-management/worker-list",
        component: <WorkerList />,
      },
      {
        // 근로자 상세조회
        name: "근로자 상세조회",
        key: "worker-detail",
        route: "/labor-management/worker-detail/:id",
        component: <WorkerDetails />,
        hidden: true,
      },
      {
        // 현장평가 관리
        name: "현장평가 관리",
        key: "work-field-evaluation-list",
        route: "/labor-management/work-field-evaluation-list",
        component: <WorkFieldEvaluationList />,
      },
      {
        // 현장평가 상세조회
        name: "현장평가 상세조회",
        key: "work-field-evaluation-detail",
        route: "/labor-management/work-field-evaluation-detail/:id",
        component: <WorkFieldEvaluationDetails />,
        hidden: true,
      },
    ],
  },

  // 정산관리
  {
    type: "collapse",
    name: "정산관리",
    key: "settlement-management",
    icon: <Icon fontSize="medium">account_balance_wallet</Icon>,
    collapse: [
      {
        // 정산내역new
        name: "정산내역",
        key: "settlement-history-ver",
        route: "/settlement-management/settlement-history-ver",
        component: <SettlementListVer2 />,
      },
      // 2025.03.05
      // 정산 상세 조회 페이지 제거 -> 정산내역 리스트 페이지 내에서 중첩 테이블로 보여짐
      // {
      //   // 정산내역 상세조회
      //   name: "정산내역 상세조회",
      //   key: "settlement-history-ver-detail",
      //   route: "/settlement-management/settlement-history-ver-detail/:id",
      //   component: <SettlementDetailsVer2 />,
      //   hidden: true,
      // },
      {
        // 정산내역new
        name: "쿠폰 정산내역",
        key: "coupon-settlement-history",
        route: "/settlement-management/coupon-settlement-history",
        component: <CouponSettlementList />,
      },
    ],
  },
  // 파트너관리
  // 파트너 살린 이유:: 뭐더라....
  {
    type: "collapse",
    name: "파트너관리",
    key: "partner-management",
    icon: <Icon fontSize="medium">handshake</Icon>,
    collapse: [
      {
        // 현장 파트너 관리
        name: "현장 파트너 관리",
        key: "site-partner-list",
        route: "/site-partner-management/site-partner-list",
        component: <PartnerList />,
      },
      {
        // 현장 파트너 상세 조회
        name: "현장 파트너 상세 조회",
        key: "site-partner-detail",
        route: "/site-partner-management/site-partner-detail/:id",
        component: <PartnerDetails />,
        hidden: true,
      },
    ],
  },

  // 쿠폰관리
  {
    type: "collapse",
    name: "쿠폰관리",
    key: "coupon-management",
    icon: <Icon fontSize="medium">card_giftcard</Icon>,
    collapse: [
      {
        name: "쿠폰관리",
        key: "coupon-list",
        route: "/coupon-management/coupon-list",
        component: <CouponList />,
      },
      {
        name: "쿠폰 상세 조회",
        key: "coupon-details",
        route: "/coupon-management/coupon-details/:id",
        component: <CouponDetails />,
        hidden: true,
      },
      {
        name: "쿠폰생성",
        key: "create-coupon",
        route: "/coupon-management/create-coupon",
        component: <CreateCoupon />,
        hidden: true,
      },
    ],
  },

  // 공지 관리
  {
    type: "collapse",
    name: "공지관리",
    key: "notice-management",
    icon: <Icon fontSize="medium">notes</Icon>,
    collapse: [
      {
        name: "공지사항 등록",
        key: "create-notice",
        route: "/notice-management/create-notice",
        component: <CreateNotice />,
        hidden: true,
      },
      {
        name: "공지사항",
        key: "notice-list",
        route: "/notice-management/notice-list",
        component: <NoticeList />,
      },
      {
        name: "공지관리 상세조회",
        key: "notice-details",
        route: "/notice-management/notice-details/:id",
        component: <NoticeDetails />,
        hidden: true,
      },
      {
        name: "공지관리 업데이트",
        key: "update-notice-details",
        route: "/notice-management/update-notice-details/:id",
        component: <UpdateNotice />,
        hidden: true,
      },
      {
        name: "이벤트",
        key: "event-list",
        route: "/notice-management/event-list",
        component: <EventList />,
      },
      {
        name: "이벤트관리 상세조회",
        key: "event-details",
        route: "/notice-management/event-details/:id",
        component: <EventDetails />,
        hidden: true,
      },
      {
        name: "이벤트 등록",
        key: "create-event",
        route: "/notice-management/create-event/",
        component: <CreateEvent />,
        hidden: true,
      },
      {
        name: "이벤트 수정",
        key: "update-event-details",
        route: "/notice-management/update-event-details/:id",
        component: <UpdateEvent />,
        hidden: true,
      },
    ],
  },

  // 채팅관리
  {
    type: "collapse",
    name: "채팅관리",
    key: "chat-management",
    icon: <Icon fontSize="medium">chat</Icon>,
    collapse: [
      {
        name: "대화 공지 관리",
        key: "chat-notice-list",
        route: "/chat-management/chat-notice-list",
        component: <ChatNoticeList />,
      },
      {
        name: "전체채팅 공지 전송",
        key: "create-chat-notice",
        route: "/chat-management/create-chat-notice",
        component: <CreateChatNotice />,
        hidden: true,
      },
      {
        name: "일자리 대화 조회",
        key: "work-chat-list",
        route: "/chat-management/work-chat-list",
        component: <WorkChatList />,
      },
      {
        name: "일대일 대화 조회",
        key: "buddy-chat-list",
        route: "/chat-management/buddy-chat-list",
        component: <BuddyChatList />,
      },
      {
        name: "대화 상세 조회",
        key: "chat-details",
        route: "/chat-management/chat-details/:id",
        component: <ChatDetails />,
        hidden: true,
      },
      {
        name: "신고 목록 조회",
        key: "report-list",
        route: "/chat-management/report-list",
        component: <ChatReports />,
      },
      {
        name: "신고 상세 조회",
        key: "report-details",
        route: "/chat-management/report-details/:id",
        component: <ChatReport />,
        hidden: true,
      },
    ],
  },
  // CS관리
  {
    type: "collapse",
    name: "CS관리",
    key: "cs-management",
    icon: <Icon fontSize="medium">support</Icon>,
    collapse: [
      {
        name: "리뷰관리",
        key: "review-list",
        route: "/cs-management/review-list",
        component: <ReviewList />,
      },
      {
        name: "Q&A",
        key: "qna-list",
        route: "/cs-management/qna-list",
        component: <QNAList />,
      },
      {
        name: "Q&A",
        key: "qna-details",
        route: "/cs-management/qna-details/:id",
        component: <QNADetails />,
        hidden: true,
      },
    ],
  },
  // 버그관리
  {
    type: "collapse",
    name: "에러제보",
    key: "bug-management",
    icon: <Icon fontSize="medium">support</Icon>,
    collapse: [
      {
        name: "에러접수내역",
        key: "bug-list",
        route: "/bug-management/bug-list",
        component: <BugList />,
      },
      {
        name: "에러제보",
        key: "bug-list-alpha",
        route: "/bug-management/bug-list-alpha",
        component: <BugListAlpha />,
        hidden: true,
      },
      {
        name: "에러제보",
        key: "new-bug",
        route: "/bug-management/new-bug",
        component: <NewBug />,
        hidden: true,
      },
      // {
      //   name: "Bug상세",
      //   key: "qna-details",
      //   route: "/bug-management/bug-details/:id",
      //   component: <BugDetails />,
      //   hidden: true,
      // },
    ],
  },
  {
    type: "collapse",
    name: "설정",
    key: "setting",
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/setting",
    component: <Setting />,
    noCollapse: true,
  },
];

export default routes;
