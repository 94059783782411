import { Grid, Icon } from "@mui/material";
import { DatePicker, Form, Pagination, PaginationProps } from "antd";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INewSettlement } from "apis/response";
import dayjs, { Dayjs } from "dayjs";
import { paramsFilter } from "utils/ParamsFilter";
import MDButton from "components/MDButton";
import Apis from "apis/remotes";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/es/form/Form";
import SettlementTable from "./components/SettlementTable";
import STcomp from "components/STcomp";

type IFilterSearch = "week" | "month" | "3month" | "year";

const disabledDate = (current: any) => {
  if (current < dayjs("2023-01-01")) return true;

  if (current > Date.now()) return true;

  return false;
};

function SettlementListVer2(): JSX.Element {
  const navigate = useNavigate();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const searchStart =
    params.get("searchStart") ?? dayjs(new Date()).subtract(1, "week").format("YYYY-MM-DD");

  const searchEnd = params.get("searchEnd") ?? dayjs(new Date()).format("YYYY-MM-DD");

  const page = Number(params.get("page") ?? 1);

  const [total, setTotal] = useState(0);

  const [settlements, setSettlements] = useState<INewSettlement[]>([]);

  const [totalResult, setTotalResult] = useState({
    totalSettlementUserCount: 0,
    settledUserCount: 0,
    unsettledUserCount: 0,
    totalManDayCount: 0,
    finalTotalAmount: 0,
    settledTotalAmount: 0,
    unsettledTotalAmount: 0,

    finalAveragePay: 0,
    // settledAveragePay: 0,
    // unsettledAveragePay: 0,
  });

  const [form] = useForm();

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page,

        searchStart,

        searchEnd,
      })}`
    );
  };

  const handleonChangeSearchRange = (filter: IFilterSearch) => {
    const searchEnd = dayjs(new Date()).format("YYYY-MM-DD");

    let searchStart;

    switch (filter) {
      case "week":
        searchStart = dayjs(searchEnd).subtract(1, "week").format("YYYY-MM-DD");

        break;

      case "month":
        searchStart = dayjs(searchEnd).subtract(1, "month").format("YYYY-MM-DD");

        break;

      case "3month":
        searchStart = dayjs(searchEnd).subtract(3, "month").format("YYYY-MM-DD");

        break;

      case "year":
        searchStart = dayjs(searchEnd).subtract(1, "year").format("YYYY-MM-DD");

        break;

      default:
        searchStart = dayjs(searchEnd).subtract(1, "week").format("YYYY-MM-DD");
    }

    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page: 1,

        searchStart,

        searchEnd,
      })}`
    );
  };

  const onFinish = (e: any) => {
    const { startEnd } = e;

    const searchStart = dayjs(startEnd[0]).format("YYYY-MM-DD");

    const searchEnd = dayjs(startEnd[1]).format("YYYY-MM-DD");

    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page: 1,

        searchStart,

        searchEnd,
      })}`
    );
  };

  const onFinish2 = (e: [Dayjs, Dayjs]) => {
    if (e === null) return;

    const startEnd = e;

    const searchStart = dayjs(startEnd[0]).format("YYYY-MM-DD");

    const searchEnd = dayjs(startEnd[1]).format("YYYY-MM-DD");

    navigate(
      `/settlement-management/settlement-history-ver${paramsFilter({
        page: 1,

        searchStart,

        searchEnd,
      })}`
    );
  };

  useEffect(() => {
    form.setFieldsValue({ startEnd: [dayjs(searchStart), dayjs(searchEnd)] });
    handleResetAllData();
  }, [page, searchStart, searchEnd, form]);

  const handleResetAllData = () => {
    console.log("호출함??");
    Apis.getSettlement({ page, perPage: 5, searchStart, searchEnd }).then((resolve) => {
      setSettlements(resolve.data.data);

      setTotal(resolve.data.total);

      let totalSettlementUserCount = isNaN(resolve.data.totalSettlementUserCount)
        ? 0
        : resolve.data.totalSettlementUserCount;

      let settledUserCount = isNaN(resolve.data.settledUserCount)
        ? 0
        : resolve.data.settledUserCount;

      let unsettledUserCount = isNaN(resolve.data.unsettledUserCount)
        ? 0
        : resolve.data.unsettledUserCount;

      let totalManDayCount = isNaN(resolve.data.totalManDayCount)
        ? 0
        : resolve.data.totalManDayCount;

      let finalTotalAmount = isNaN(resolve.data.finalTotalAmount)
        ? 0
        : resolve.data.finalTotalAmount;

      let settledTotalAmount = isNaN(resolve.data.settledTotalAmount)
        ? 0
        : resolve.data.settledTotalAmount;
      let unsettledTotalAmount = isNaN(resolve.data.unsettledTotalAmount)
        ? 0
        : resolve.data.unsettledTotalAmount;

      let finalAveragePay = isNaN(finalTotalAmount / totalManDayCount)
        ? 0
        : finalTotalAmount / totalManDayCount;

      setTotalResult({
        totalSettlementUserCount,
        settledUserCount,
        unsettledUserCount,
        totalManDayCount,
        finalTotalAmount,
        settledTotalAmount,
        unsettledTotalAmount,
        finalAveragePay,
      });
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox>
        <Grid container py={3} spacing={1}>
          <Grid item sm={12} md={4}>
            <Form form={form} onFinish={onFinish}>
              <Grid item container>
                <Grid item xs={4}>
                  <MDButton color="info" type="submit">
                    기간조회
                  </MDButton>
                </Grid>

                <Grid item xs={8}>
                  <FormItem name="startEnd">
                    <DatePicker.RangePicker disabledDate={disabledDate} onChange={onFinish2} />
                  </FormItem>
                </Grid>
              </Grid>
            </Form>
          </Grid>

          <Grid item sm={12} md={8}>
            <MDBox display={"flex"} gap={1}>
              <MDButton size="small" color="info" onClick={() => handleonChangeSearchRange("week")}>
                최근 일주일
              </MDButton>

              <MDButton
                size="small"
                color="info"
                onClick={() => handleonChangeSearchRange("month")}
              >
                최근 1달
              </MDButton>

              <MDButton
                size="small"
                color="info"
                onClick={() => handleonChangeSearchRange("3month")}
              >
                3개월 이내
              </MDButton>

              <MDButton size="small" color="info" onClick={() => handleonChangeSearchRange("year")}>
                최근 1년
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} gap={2} display={"flex"}>
            <STcomp
              title={"정산 된 인원"}
              amount={totalResult.settledUserCount}
              color="secondary"
              variant="contained"
            />
            <STcomp
              title={"미 정산 인원"}
              amount={totalResult.unsettledUserCount}
              color="secondary"
              variant="contained"
            />
            <STcomp
              title={"총 정산 인원"}
              amount={totalResult.totalSettlementUserCount}
              color="secondary"
              variant="contained"
            />
          </Grid>

          <Grid item xs={12} md={12} gap={2} display={"flex"}>
            <STcomp
              title={"정산 된 금액"}
              amount={`${totalResult.settledTotalAmount?.toLocaleString()}원`}
              color="secondary"
              variant="contained"
            />
            <STcomp
              title={"미 정산 금액"}
              amount={`${totalResult.unsettledTotalAmount?.toLocaleString()}원`}
              color="secondary"
              variant="contained"
            />
            <STcomp
              title={"총 정산 금액"}
              amount={`${totalResult.finalTotalAmount?.toLocaleString()}원`}
              color="secondary"
              variant="contained"
            />
          </Grid>
          <Grid item xs={12} md={6} gap={2} display={"flex"}>
            <STcomp
              title={"총 공수"}
              amount={totalResult.totalManDayCount}
              color="secondary"
              variant="contained"
            />
            <STcomp
              title={"평균 단가"}
              amount={`${totalResult.finalAveragePay?.toLocaleString()}원`}
              color="secondary"
              variant="contained"
            />
          </Grid>
          <Grid item xs={12} md={6} gap={2} display={"flex"}>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`${process.env.REACT_APP_ENDPOINT}settlement/data/download`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDButton endIcon={<Icon>downloadIcon</Icon>} variant="gradient" color="info">
                정산 대상 유저 엑셀 다운
              </MDButton>
            </a>
          </Grid>
        </Grid>

        <MDBox flex={"flex"} flexDirection={"column"}>
          {settlements.map((work, index) => (
            <SettlementTable
              key={`depao-table-${index}`}
              work={work}
              navigate={navigate}
              handleResetAllData={handleResetAllData}
            />
          ))}
        </MDBox>

        <MDBox my={2}>
          <Pagination
            current={page}
            onChange={handleOnChangePage}
            pageSize={5}
            total={total}
            showSizeChanger={false}
          />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default SettlementListVer2;
